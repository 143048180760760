import { useCallback, useState } from 'react';
import { useWindowSizeContext } from 'contexts/window-size';
import {
  CREATE_CLUB_MODAL_TYPE,
  CREATE_GAME_EVENT_MODAL_TYPE,
  modalStaticProps,
} from './constants';
import Modal from 'features/modal/components/modal';
import { DARK_THEME } from 'features/modal';
import Form from 'common/components/form';
import style from './Navbar.module.scss';
import { useCreateClub, useProps } from './hooks';
import Match from 'features/match';
import { REACT_GA_CREATE_TEAM_MODAL } from 'common/constants/react-google-analytics/modals';
import { CreateEditGameEvent } from 'features/game-event-features';
import { HeaderWrapper } from '_metronic/layout/components/header/HeaderWrapper';

const Navbar = () => {
  const { isMobileView } = useWindowSizeContext();
  const { selectedMatch, closeMatchModal } = useProps();

  const [modalOpened, setModalOpened] = useState(null);

  const openCreateGameEventModal = useCallback(() => {
    setModalOpened(CREATE_GAME_EVENT_MODAL_TYPE);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpened(null);
  }, []);

  const { onCreateClub, getCreateClubInputProps } = useCreateClub({
    closeModal,
  });

  const quickLinksProps = {
    title: 'Quick Actions',
    linkItems: [
      {
        title: 'Create Game',
        description: 'Game Builder',
        icon: 'rocket',
        isOdd: true,
        onClick: openCreateGameEventModal,
        isActive: true,
      },
      {
        title: 'Create Team',
        description: 'Coming soon',
        icon: 'shield',
        isOdd: false,
        onClick: () => {
          alert('Create Team');
        },
      },
      {
        title: 'Create League',
        description: 'Coming soon',
        icon: 'data',
        isOdd: true,
      },
    ],
  };

  const headerProps = {
    headerMenuItems: [],
  };

  return (
    <div>
      <HeaderWrapper
        quickLinksProps={quickLinksProps}
        headerProps={headerProps}
      />
      {/* -- MODALS -- */}
      {modalOpened === CREATE_CLUB_MODAL_TYPE && (
        <Modal
          fullScreen={isMobileView}
          title={modalStaticProps[CREATE_CLUB_MODAL_TYPE].title}
          onClose={closeModal}
          variant='center'
          theme={DARK_THEME}
          customClassName={style.modal}
          reactGAModalView={REACT_GA_CREATE_TEAM_MODAL}
        >
          <Form inputs={getCreateClubInputProps()} onConfirm={onCreateClub} />
        </Modal>
      )}

      {modalOpened === CREATE_GAME_EVENT_MODAL_TYPE && (
        <CreateEditGameEvent onClose={closeModal} />
      )}

      {selectedMatch && (
        <Match onClose={closeMatchModal} data={selectedMatch} />
      )}
    </div>
  );
};

export default Navbar;
