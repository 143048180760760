import { getCombinedStreetData } from 'common/api/streetService';
import { apiServiceRolesMap } from 'common/constants/apis';
import { useApi } from 'common/hooks';
import { useUserSessionContext } from 'contexts/user-session';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  STREET_GAME_EVENT_DELETE_SUBSCRIPTION_ID,
  STREET_GAME_EVENT_SELECTED_GAME_EVENT_SUBSCRIPTION_ID,
  STREET_GAME_EVENT_UPDATE_SUBSCRIPTION_ID,
  STREET_SCREENS,
  existingStreetDataTypes,
  streetDataTypeMap,
} from '../constants';
import { formatStreetV2Cards } from '../utils';
import useStreetNavigation from './useStreetNavigation';
import {
  GAME_EVENT_DELETE,
  GAME_EVENT_UPDATE,
} from 'contexts/events/constants';
import { useEventsContext } from 'contexts/events';
import { useMetaContext } from 'contexts/meta';

const useProps = () => {
  const { asyncWrapper } = useApi();
  const { subscribe, unsubscribe } = useEventsContext();
  const { activeRole } = useUserSessionContext();
  const { loading, unsetLoading } = useMetaContext();
  const { selectedScreen, selectEntranceScreen, streetNavigationPropsSet } =
    useStreetNavigation();

  const [selectedMatch, setSelectedMatch] = useState(null);
  const [matchOfferedClub, setMatchOfferedClub] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [generalPlayers, setGeneralPlayers] = useState([]);
  // NOTE handle this
  const [, setShortlistPlayers] = useState([]);
  const [generalClubs, setGeneralClubs] = useState([]);
  const [shortlistClubs, setShortlistClubs] = useState([]);
  const [cardItems, setCardItems] = useState([]);

  // -- game event
  const [selectedGameEvent, setSelectedGameEvent] = useState(null);

  const updateSelectedGameEventData = useCallback(
    data => {
      if (selectedGameEvent && data?._id === selectedGameEvent?._id) {
        setSelectedGameEvent(data);
      }
    },
    [selectedGameEvent]
  );

  useEffect(() => {
    subscribe(GAME_EVENT_UPDATE, {
      cb: updateSelectedGameEventData,
      id: STREET_GAME_EVENT_SELECTED_GAME_EVENT_SUBSCRIPTION_ID,
    });
  }, [subscribe, unsubscribe, updateSelectedGameEventData]);

  const closeGameEvent = useCallback(() => {
    setSelectedGameEvent(null);
    unsubscribe(
      GAME_EVENT_UPDATE,
      STREET_GAME_EVENT_SELECTED_GAME_EVENT_SUBSCRIPTION_ID
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addPlayerToShortlist = useCallback(({ playerId }) => {
    let player;
    setGeneralPlayers(curr => {
      const updated = curr.filter(item => {
        if (item?._id === playerId) player = item;
        return item?._id !== playerId;
      });

      return updated;
    });
    setShortlistPlayers(curr => [player, ...curr]);
  }, []);

  const removePlayerFromShortlist = useCallback(({ playerId }) => {
    let player;
    setShortlistPlayers(curr =>
      curr.filter(item => {
        if (item?._id === playerId) player = item;
        return item?._id !== playerId;
      })
    );
    setGeneralPlayers(curr => [...curr, player]);
  }, []);

  const addClubToShortlist = useCallback(({ clubId }) => {
    let club;
    setGeneralClubs(curr =>
      curr.filter(item => {
        if (item?._id === clubId) club = item;

        return item?._id !== clubId;
      })
    );
    setShortlistClubs(curr => [club, ...curr]);
  }, []);

  const removeClubFromShortlist = useCallback(({ clubId }) => {
    let club;
    setShortlistClubs(curr =>
      curr.filter(item => {
        if (item?._id === clubId) club = item;
        return item?._id !== clubId;
      })
    );
    setGeneralClubs(curr => [...curr, club]);
  }, []);

  const closeMatchModal = useCallback(() => {
    setSelectedMatch(null);
    setMatchOfferedClub(null);
  }, []);

  const loadStreetData = useCallback(
    (location, type) => {
      asyncWrapper(async () => {
        if (activeRole?.id && existingStreetDataTypes.includes(type)) {
          const data = await getCombinedStreetData(
            apiServiceRolesMap[activeRole.type],
            activeRole?.id,
            location,
            type
          );

          if (typeof data === 'object') setApiData(data?.data);
        }
      });
    },
    [activeRole?.id, activeRole.type, asyncWrapper]
  );

  const loadLocalStreetData = useCallback(
    type => {
      loadStreetData(activeRole?.gmClub?.city || activeRole?.city, type);
    },
    [activeRole?.city, activeRole?.gmClub?.city, loadStreetData]
  );

  // NOTE FAS switching off the gm roles feature
  const tabCityLabel = activeRole?.geoInfo?.city;
  // const tabCityLabel = useMemo(
  //   () =>
  //     activeRole.type === PLAYER_USER_ROLE
  //       ? activeRole?.city
  //       : activeRole?.gmClub?.city,
  //   [activeRole.city, activeRole.gmClub.city, activeRole.type]
  // );
  const tabsData = useMemo(
    () => [
      {
        label: tabCityLabel || 'city', // TODO tabCityLabel is no longer valid
        onClick: () => {
          // NOTE TODO first arg is always undefined bc the city is not set in the auth session
          loadStreetData(
            activeRole?.gmClub?.city || activeRole?.city,
            streetDataTypeMap[selectedScreen]
          );
        },
      },
      // NOTE FAS switching off the world view
      // {
      //   label: 'World',
      //   onClick: () => {
      //     loadStreetData('world', streetDataTypeMap[selectedScreen]);
      //   },
      // },
    ],
    [
      activeRole?.city,
      activeRole?.gmClub?.city,
      loadStreetData,
      selectedScreen,
      tabCityLabel,
    ]
  );

  useEffect(() => {
    if (apiData) {
      setGeneralClubs(apiData?.generalClubs);
      setShortlistClubs(apiData?.shortlistClubs);
      setGeneralPlayers(apiData?.players);
      setShortlistPlayers(apiData?.shortlistPlayers);
    }
  }, [apiData]);

  const playersShortslistCallbacks = useMemo(
    () => ({
      addToShortlistCB: addPlayerToShortlist,
      removeFromShortlistCB: removePlayerFromShortlist,
    }),
    [addPlayerToShortlist, removePlayerFromShortlist]
  );
  const clubsShortlistCallbacks = useMemo(
    () => ({
      addToShortlistCB: addClubToShortlist,
      removeFromShortlistCB: removeClubFromShortlist,
    }),
    [addClubToShortlist, removeClubFromShortlist]
  );

  // TODO QUESTION -> adjust street API -> to read from the new API
  useEffect(() => {
    setCardItems(
      formatStreetV2Cards(
        Object.assign({}, apiData, {
          generalClubs,
          shortlistClubs,
          generalPlayers,
          // shortlistPlayers,
        }),
        activeRole?.id,
        activeRole?.type,
        setMatchOfferedClub,
        playersShortslistCallbacks,
        clubsShortlistCallbacks,
        setSelectedGameEvent
      )
    );
  }, [
    apiData,
    activeRole._id,
    activeRole?.id,
    activeRole?.type,
    playersShortslistCallbacks,
    clubsShortlistCallbacks,
    generalClubs,
    shortlistClubs,
    generalPlayers,
    // shortlistPlayers,
  ]);

  const updateGameEventsData = useCallback(
    gameEventData => {
      setApiData(curr => {
        const nextApiData = { ...curr };
        const gameEventIndex = nextApiData.gameEvents.findIndex(
          gameEvent => gameEvent._id === gameEventData._id
        );
        if (gameEventIndex > -1) {
          nextApiData.gameEvents[gameEventIndex] = gameEventData;
        } else {
          nextApiData.gameEvents.push(gameEventData);
        }

        return nextApiData;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const removeGameEventFromList = useCallback(gameEventId => {
    setApiData(curr => {
      const nextApiData = { ...curr };
      nextApiData.gameEvents = nextApiData.gameEvents.filter(
        gameEvent => gameEvent._id !== gameEventId
      );

      return nextApiData;
    });
  }, []);

  useEffect(() => {
    const isPlayersScreenReady =
      selectedScreen === STREET_SCREENS.playersStreetScreen && !!generalPlayers;
    const isGamesScreenReady =
      selectedScreen === STREET_SCREENS.gamesStreetScreen && !!apiData;

    const isEntranceScreen = selectedScreen === null;

    if (isPlayersScreenReady || isGamesScreenReady || isEntranceScreen) {
      unsetLoading();
    }
  }, [apiData, generalPlayers, selectedScreen, unsetLoading]);

  useEffect(() => {
    subscribe(GAME_EVENT_UPDATE, {
      cb: updateGameEventsData,
      id: STREET_GAME_EVENT_UPDATE_SUBSCRIPTION_ID,
    });
    subscribe(GAME_EVENT_DELETE, {
      cb: removeGameEventFromList,
      id: STREET_GAME_EVENT_DELETE_SUBSCRIPTION_ID,
    });

    return () => {
      unsubscribe(GAME_EVENT_UPDATE, STREET_GAME_EVENT_UPDATE_SUBSCRIPTION_ID);
      unsubscribe(GAME_EVENT_DELETE, STREET_GAME_EVENT_DELETE_SUBSCRIPTION_ID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    cardItems,
    // game event
    selectedGameEvent,
    closeGameEvent,

    selectedMatch,
    setSelectedMatch,
    matchOfferedClub,
    closeMatchModal,

    // players
    players: generalPlayers,

    tabsData,

    loadLocalStreetData,

    // street navigation
    selectedScreen,
    selectEntranceScreen,
    streetNavigationPropsSet,

    // loading
    loading,
  };
};

export default useProps;
