import { MenuItem } from '../MenuItem';
import { MenuInnerWithSub } from '../MenuInnerWithSub';
import { MegaMenu } from '../MegaMenu';
import MenuInnerPropTypes from './MenuInner.propTypes';
import { useCallback } from 'react';
// import { useIntl } from 'react-intl';
// import { format } from 'date-fns';

MenuInner.propTypes = MenuInnerPropTypes;
// showcase of how to use the menu items
MenuInner.defaultProps = {
  items: [
    {
      title: 'Layout Builder',
      to: '/builder',
      type: 'item',
    },
    {
      title: 'Crafted',
      to: '/crafted',
      type: 'inner',
      menuPlacement: 'bottom-start',
      menuTrigger: 'click',
      subItems: [
        {
          title: 'Pages',
          to: '/crafted/pages',
          icon: 'element-plus',
          hasArrow: true,
          type: 'inner',
          menuPlacement: 'right-start',
          menuTrigger: `{default:'click', lg: 'hover'}`,
          subItems: [
            {
              title: 'Profile',
              type: 'inner',
              to: '/crafted/pages/profile',
              hasArrow: true,
              hasBullet: true,
              menuPlacement: 'right-start',
              menuTrigger: `{default:'click', lg: 'hover'}`,
              subItems: [
                {
                  to: '/crafted/pages/profile/overview',
                  title: 'Overview',
                  hasBullet: true,
                },
                {
                  to: '/crafted/pages/profile/projects',
                  title: 'Projects',
                  hasBullet: true,
                },
                {
                  to: '/crafted/pages/profile/campaigns',
                  title: 'Campaigns',
                  hasBullet: true,
                },
                {
                  to: '/crafted/pages/profile/documents',
                  title: 'Documents',
                  hasBullet: true,
                },
                {
                  to: '/crafted/pages/profile/connections',
                  title: 'Connections',
                  hasBullet: true,
                },
              ],
            },
            {
              title: 'Wizards',
              to: '/crafted/pages/wizards',
              hasArrow: true,
              hasBullet: true,
              menuPlacement: 'right-start',
              menuTrigger: `{default:'click', lg: 'hover'}`,
              type: 'inner',
              subItems: [
                {
                  to: '/crafted/pages/wizards/horizontal',
                  title: 'Horizontal',
                  hasBullet: true,
                },
                {
                  to: '/crafted/pages/wizards/vertical',
                  title: 'Vertical',
                  hasBullet: true,
                },
              ],
            },
          ],
        },
        {
          type: 'inner',
          title: 'Accounts',
          to: '/crafted/accounts',
          icon: 'profile-circle',
          hasArrow: true,
          menuPlacement: 'right-start',
          menuTrigger: `{default:'click', lg: 'hover'}`,
          subItems: [
            {
              to: '/crafted/account/overview',
              title: 'Overview',
              hasBullet: true,
            },
            {
              to: '/crafted/account/settings',
              title: 'Settings',
              hasBullet: true,
            },
          ],
        },
        {
          title: 'Errors',
          to: '/error',
          type: 'inner',
          icon: 'fingerprint-scanning',
          hasArrow: true,
          menuPlacement: 'right-start',
          menuTrigger: `{default:'click', lg: 'hover'}`,
          subItems: [
            {
              to: '/error/404',
              title: 'Error 404',
              hasBullet: true,
            },
            {
              to: '/error/500',
              title: 'Error 500',
              hasBullet: true,
            },
          ],
        },
        {
          type: 'inner',
          title: 'Widgets',
          to: '/crafted/widgets',
          icon: 'element-11',
          hasArrow: true,
          menuPlacement: 'right-start',
          menuTrigger: `{default:'click', lg: 'hover'}`,
          subItems: [
            {
              to: '/crafted/widgets/lists',
              title: 'Lists',
              hasBullet: true,
            },
            {
              to: '/crafted/widgets/statistics',
              title: 'Statistics',
              hasBullet: true,
            },
            {
              to: '/crafted/widgets/charts',
              title: 'Charts',
              hasBullet: true,
            },
            {
              to: '/crafted/widgets/mixed',
              title: 'Mixed',
              hasBullet: true,
            },
            {
              to: '/crafted/widgets/tables',
              title: 'Tables',
              hasBullet: true,
            },
            {
              to: '/crafted/widgets/feeds',
              title: 'Feeds',
              hasBullet: true,
            },
          ],
        },
      ],
    },
    {
      title: 'Apps',
      to: '/apps',
      type: 'inner',
      menuPlacement: 'bottom-start',
      menuTrigger: 'click',
      subItems: [
        {
          title: 'Chat',
          type: 'inner',
          to: '/apps/chat',
          icon: 'message-text-2',
          hasArrow: true,
          menuPlacement: 'right-start',
          menuTrigger: `{default:'click', lg: 'hover'}`,
          subItems: [
            {
              to: '/apps/chat/private-chat',
              title: 'Private Chat',
              hasBullet: true,
            },
            {
              to: '/apps/chat/group-chat',
              title: 'Group Chart',
              hasBullet: true,
            },
            {
              to: '/apps/chat/drawer-chat',
              title: 'Drawer Chart',
              hasBullet: true,
            },
          ],
        },
        {
          icon: 'shield-tick',
          to: '/apps/user-management/users',
          title: 'User management',
          type: 'item',
        },
      ],
    },
    {
      isMega: true,
      title: 'Mega menu',
      to: '/mega-menu',
      type: 'inner',
      menuPlacement: 'bottom-start',
      menuTrigger: 'click',
      subItems: [
        {
          type: 'mega',
        },
      ],
    },
  ],
};

export function MenuInner({ items }) {
  const renderItemWithSubs = useCallback(item => {
    const { type, subItems, ...rest } = item;
    const subItemsToRender = item?.subItems?.map((subItem, index) => {
      const { type, subItems, ...rest } = subItem;
      switch (type) {
        case 'inner':
          return (
            <MenuInnerWithSub key={index} {...rest}>
              {subItems?.map(renderItemWithSubs)}
            </MenuInnerWithSub>
          );
        case 'mega':
          return <MegaMenu key={index} />;
        case 'item':
        default:
          return <MenuItem key={index} {...rest} />;
      }
    });

    return <MenuInnerWithSub {...rest}>{subItemsToRender}</MenuInnerWithSub>;
  }, []);

  const renderItems = useCallback(
    () =>
      items?.map((item, index) => {
        const { type, ...rest } = item;
        switch (type) {
          case 'item':
            return <MenuItem key={index} {...rest} />;
          case 'inner':
            return renderItemWithSubs(item);
          default:
            return null;
        }
      }),

    [items, renderItemWithSubs]
  );

  return <>{renderItems(items)}</>;
}
