import React, { useEffect } from 'react';
import { initRefreshTokenInIntervals } from 'common/api/authService';
import { useAuthContext } from 'contexts/auth';
import { useMetaContext } from 'contexts/meta';
import { getRoutes, goToStreetPage, setRouting } from 'common/utils/routing';
import { authRoutes, mainRoutes } from 'routes';
import Loading, { GLOBAL_LOADING_TYPE } from 'common/components/loading';
import ErrorPage from 'pages/error-boundary/components/error-page';
import Toasts from 'features/toasts';
import { useLocation } from 'react-router-dom';
import { scrollToTop } from 'common/utils/dom';
import Preload from 'features/preload';
import { MasterLayout } from '_metronic/layout/MasterLayout';
// import testRoutes from 'routes/test';

const AppContainer = () => {
  const { loadUser, user } = useAuthContext();
  const { loading, crashError, outsideClick } = useMetaContext();
  const { pathname } = useLocation();

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  // to refresh access token in intervals if there is a user logged in
  useEffect(() => {
    const interval = initRefreshTokenInIntervals(user);

    return () => {
      clearInterval(interval);
    };
  }, [user]);

  const routes = user ? mainRoutes : authRoutes;

  if (crashError) return <ErrorPage onGoBack={goToStreetPage} />;

  return (
    <MasterLayout isPrivate={!!user}>
      <div onClick={outsideClick}>
        <Preload />
        {loading === GLOBAL_LOADING_TYPE && (
          <Loading variant={GLOBAL_LOADING_TYPE} />
        )}
        <Toasts />
        {/* FOR DEV PURPOSES */}
        {/* {setRouting(getRoutes([...testRoutes, ...authRoutes]))} */}
        {setRouting(getRoutes([...routes]))}
      </div>
    </MasterLayout>
  );
};

export default AppContainer;
