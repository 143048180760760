import { updateUserBio, updateUserV2 } from 'common/api/userService';
import { DEFAULT_USER_IMAGE } from 'common/constants/common-props';
import { COUNTRY_CODES, COUNTRY_NAMES } from 'common/constants/geoData';
import {
  REACT_GA_REBRAND_SIGNATURE_EVENT,
  REACT_GA_SELECT_CALL_SIGN_SIGNATURE_EVENT,
  REACT_GA_SELECT_NO_SIGNATURE_EVENT,
  REACT_GA_SELECT_NUMBER_SIGNATURE_EVENT,
} from 'common/constants/react-google-analytics/events';
import { REACT_GA_UPLOAD_PROFILE_IMAGE_MODAL } from 'common/constants/react-google-analytics/modals';
import { useApi, useForm } from 'common/hooks';
import { calculateAge } from 'common/utils/dates';
import {
  formatImage,
  getDefaultDateFormattedString,
} from 'common/utils/formatting';
import { useAuthContext } from 'contexts/auth';
import ReactGA from 'react-ga';
import { USER_PROFILE_IMAGE_UPLOAD_TYPE } from 'features/image-uploader/constants';
import { useMemo, useCallback } from 'react';
import {
  ADD_PROFILE_IMAGE_TOOLTIP,
  bioFormFields,
  BIO_FORM_KEYS,
  NICKNAME_SIGNATURE_TYPE,
  NO_SIGNATURE,
  NUMBER_SIGNATURE_TYPE,
  signatureFormFields,
  SIGNATURE_FORM_KEYS,
  staticProps,
  USER_BIO_SUCCESSFULLY_UPDATED_MESSAGE,
  USER_SIGNATURE_SUCCESSFULLY_UPDATED_MESSAGE,
  MY_GAME_EVENTS,
  tabURLPaths,
  BIO_VIEW,
} from '../constants';
import { editBioSchema, editSignatureSchema } from '../schemas';
import { useLocation } from 'react-router-dom';
import { mapInitBioForm } from '../mappers';
import useUser from './useUser';

const usePropsV2 = ({ closeModal }) => {
  const { isProfileOwner } = useAuthContext();
  const { userProfile, setUserProfile } = useUser();
  const { apiWrapper } = useApi();
  const { pathname } = useLocation();

  const getActiveTabFromURL = useCallback(() => {
    const activeTab = pathname.split('/')[3];

    if (activeTab === tabURLPaths.MY_GAME_EVENTS) {
      return MY_GAME_EVENTS;
    } else {
      return BIO_VIEW;
    }
  }, [pathname]);

  // signature
  const saveSignatureEdit = useCallback(
    async data => {
      const response = await apiWrapper(
        () => updateUserBio(data),
        USER_SIGNATURE_SUCCESSFULLY_UPDATED_MESSAGE
      );

      if (response?.data) {
        setUserProfile(response.data);
        ReactGA.event(REACT_GA_REBRAND_SIGNATURE_EVENT);
      }
    },
    [apiWrapper, setUserProfile]
  );

  const initSignatureForm = useMemo(() => {
    const keys = SIGNATURE_FORM_KEYS;
    const initSignatureForm = {};
    keys.forEach(key => {
      initSignatureForm[key] = { value: userProfile?.[key], error: null };
    });

    return initSignatureForm;
  }, [userProfile]);
  const {
    submit: onSignatureEditSave,
    getInputProps: getSignatureInputProps,
    refreshFormFieldValues: refreshSignatureFormFieldValues,
  } = useForm(
    initSignatureForm,
    signatureFormFields,
    editSignatureSchema,
    saveSignatureEdit
  );

  const signatureSaveCTAProps = useMemo(
    () => ({
      onClick: onSignatureEditSave,
      label: staticProps.signatureSettings.onSaveLabel,
    }),
    [onSignatureEditSave]
  );

  const chooseNicknameSignature = useCallback(() => {
    saveSignatureEdit({ signatureType: NICKNAME_SIGNATURE_TYPE });
    ReactGA.event(REACT_GA_SELECT_CALL_SIGN_SIGNATURE_EVENT);
  }, [saveSignatureEdit]);
  const chooseNumberSignature = useCallback(() => {
    saveSignatureEdit({ signatureType: NUMBER_SIGNATURE_TYPE });
    ReactGA.event(REACT_GA_SELECT_NUMBER_SIGNATURE_EVENT);
  }, [saveSignatureEdit]);
  const chooseNoSignature = useCallback(() => {
    saveSignatureEdit({ signatureType: NO_SIGNATURE });
    ReactGA.event(REACT_GA_SELECT_NO_SIGNATURE_EVENT);
  }, [saveSignatureEdit]);

  const signatureSettingsCTAProps = useMemo(
    () => [
      {
        onClick: chooseNicknameSignature,
        label: staticProps.signatureSettings.nicknameLabel,
        type: NICKNAME_SIGNATURE_TYPE,
      },
      {
        onClick: chooseNumberSignature,
        label: staticProps.signatureSettings.numberLabel,
        type: NUMBER_SIGNATURE_TYPE,
      },
      {
        onClick: chooseNoSignature,
        label: staticProps.signatureSettings.noSignatureLabel,
        type: NO_SIGNATURE,
      },
    ],
    [chooseNicknameSignature, chooseNoSignature, chooseNumberSignature]
  );

  // bio
  const initBioForm = useMemo(() => {
    const keys = BIO_FORM_KEYS;
    const mappedUser = mapInitBioForm(userProfile);

    const initBioForm = {};
    keys.forEach(key => {
      let value = mappedUser?.[key];
      if (key === 'country') {
        value = COUNTRY_CODES[mappedUser?.[key]];
      }
      initBioForm[key] = { value, error: null };
    });

    return initBioForm;
  }, [userProfile]);

  const bioItems = useMemo(
    () => [
      { label: 'first name:', value: userProfile?.firstName },
      { label: 'last name:', value: userProfile?.lastName },
      { label: 'age:', value: calculateAge(userProfile?.birthDate) },
      {
        label: `current city${
          isProfileOwner ? ' (can be updated once per month)' : ''
        }: `,
        value: userProfile?.geoInfo?.city,
      },
      {
        label: `nationality${
          isProfileOwner ? ' (can be updated every three months)' : ''
        }: `,
        value: userProfile?.geoInfo?.nationality,
      },
      {
        label: 'joined on:',
        value: getDefaultDateFormattedString(userProfile?.createdAt),
      },
    ],
    [
      isProfileOwner,
      userProfile?.birthDate,
      userProfile?.createdAt,
      userProfile?.firstName,
      userProfile?.geoInfo?.city,
      userProfile?.geoInfo?.nationality,
      userProfile?.lastName,
    ]
  );

  const saveBioEdit = useCallback(
    async data => {
      const formattedData = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        geoInfo: {
          nationality: COUNTRY_NAMES[data?.country],
          city: data?.city,
        },
      };

      const response = await apiWrapper(
        () => updateUserV2(formattedData),
        USER_BIO_SUCCESSFULLY_UPDATED_MESSAGE
      );

      if (response?.data) {
        setUserProfile(response.data);
      }

      closeModal();
    },
    [apiWrapper, closeModal, setUserProfile]
  );

  const {
    submit: confirmBioUpdates,
    getInputProps,
    refreshFormFieldValues,
  } = useForm(initBioForm, bioFormFields, editBioSchema, saveBioEdit);

  // heading
  const headingProps = useMemo(
    () => ({
      img: userProfile?.image?.url
        ? formatImage(userProfile?.image?.url)
        : DEFAULT_USER_IMAGE,
      heading: `${userProfile?.firstName} ${userProfile?.lastName}`,
      subtext: `📍 ${userProfile?.geoInfo?.city}`,
      withFlag: userProfile?.geoInfo?.nationality,
      subtext2: ` ${userProfile?.geoInfo?.nationality}`,
      imageUploadProps: {
        addImageTooltip: ADD_PROFILE_IMAGE_TOOLTIP,
        reactGAModalView: REACT_GA_UPLOAD_PROFILE_IMAGE_MODAL,
        type: USER_PROFILE_IMAGE_UPLOAD_TYPE,
      },
    }),
    [
      userProfile?.firstName,
      userProfile?.geoInfo?.city,
      userProfile?.geoInfo?.nationality,
      userProfile?.image?.url,
      userProfile?.lastName,
    ]
  );

  return {
    // signature
    initSignatureForm,
    signatureSaveCTAProps,
    signatureSettingsCTAProps,
    getSignatureInputProps,
    refreshSignatureFormFieldValues,

    // bio
    initBioForm,
    bioItems,
    refreshFormFieldValues,
    getInputProps,
    confirmBioUpdates,

    // heading
    headingProps,

    // matches NOTE legacy
    matchItemProps: {
      matchItems: [],
    },

    getActiveTabFromURL,

    userProfile,
  };
};

export default usePropsV2;
