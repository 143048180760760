import ElementWithTooltip from 'common/components/element-with-tooltip';
import PropTypes from 'prop-types';
import './FeatureActionButton.scss';

const className = 'c-FeatureActionButton';

const FeatureActionButton = ({
  Element,
  renderIcon,
  onClick,
  tooltip,
  customClassNames,
}) => (
  <div className={`${className} ${customClassNames}`}>
    <ElementWithTooltip tooltip={tooltip}>
      <div onClick={onClick} className={`${className}__innerWrapper`}>
        {Element ? <Element /> : renderIcon()}
      </div>
    </ElementWithTooltip>
  </div>
);

FeatureActionButton.propTypes = {
  renderIcon: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.string.isRequired,
  Element: PropTypes.elementType,
  customClassNames: PropTypes.string,
};

export default FeatureActionButton;
