import { useCallback, useState } from 'react';
import style from './RatePlayerCard.module.scss';
import PropTypes from 'prop-types';
import { useMetaContext } from 'contexts/meta';
import ProgressCircle from 'common/components/progress-circle';
import {
  PLAYER_RATE_ERROR_MESSAGE,
  PLAYER_RATE_SUCCESS_MESSAGE,
} from 'features/player-card-v2/constants';
import {
  NUMERICAL_PLAYER_EXPERIENCE_LEVELS,
  PLAYER_EXPERIENCE_LEVELS,
  PlayerExperienceTypes,
} from 'common/constants/users';

const RatePlayerCard = ({ initialRating, onRatingChange }) => {
  const [rating, _setRating] = useState(initialRating);
  const { setNeutralToastMessage, setNonCrashErrorToastMessage } =
    useMetaContext();

  const setRating = useCallback(
    async (value, rate) => {
      _setRating(value);

      try {
        if (onRatingChange) await onRatingChange(rate);
        setNeutralToastMessage(PLAYER_RATE_SUCCESS_MESSAGE);
      } catch (error) {
        setNonCrashErrorToastMessage(PLAYER_RATE_ERROR_MESSAGE);
      }
    },
    [onRatingChange, setNeutralToastMessage, setNonCrashErrorToastMessage]
  );

  const renderRateButtons = useCallback(() => {
    const rates = PLAYER_EXPERIENCE_LEVELS.map(item => ({
      name: item,
      rate: NUMERICAL_PLAYER_EXPERIENCE_LEVELS[item],
      value:
        Math.round(
          ((NUMERICAL_PLAYER_EXPERIENCE_LEVELS[item] * 100) / 6) * 100
        ) / 100,
    })).toSpliced(5, 1); // NOTE hiding World Class

    return rates.map((item, i) => (
      <button
        key={item.name}
        className={style[`rate-${i + 1}`]}
        onClick={() => setRating(item.value, item.rate)}
        disabled={item.name === PlayerExperienceTypes.WORLD_CLASS}
      >
        {item.name}
      </button>
    ));
  }, [setRating]);

  return (
    <div className={style.wrapper}>
      <div className={style.progress}>
        <ProgressCircle progress={rating} showPercentage />
      </div>
      <div className={style.controls}>{renderRateButtons()}</div>
    </div>
  );
};

RatePlayerCard.defaultProps = {
  initialRating: 0,
};

RatePlayerCard.propTypes = {
  initialRating: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RatePlayerCard;
