import { useCallback } from 'react';
import { INTERNATIONAL_SKILL_LEVEL_COLOR } from 'common/constants/theming';
import { RADIUS } from './constants';
import { getOffset } from './utils';

const ProgressCircle = props => {
  const getText = useCallback(() => {
    const { progress, textStyle, percentSpacing } = props;

    return (
      <text
        style={textStyle}
        fill={INTERNATIONAL_SKILL_LEVEL_COLOR}
        x={RADIUS}
        y={RADIUS}
        textAnchor='middle'
        dominantBaseline='central'
      >
        {progress}
        <tspan dx={percentSpacing}>%</tspan>
      </text>
    );
  }, [props]);

  const render = useCallback(() => {
    const text = getText();
    const { progress, size, lineWidth, animationDuration, onAnimationEnd } =
      props;
    const strokeDashoffset = getOffset(progress);
    const transition = `stroke-dashoffset ${animationDuration} ease-out`;

    const strokeLinecap = 'round';
    const svgSize = size;

    return (
      <svg width={svgSize} height={svgSize} viewBox='-25 -25 400 400'>
        <defs>
          <linearGradient id='grad1' x1='0%' y1='0%' x2='100%' y2='0%'>
            <stop
              offset='0%'
              style={{ stopColor: '#612b8f', stopOpacity: 1 }}
            />
            <stop
              offset='100%'
              style={{ stopColor: '#004d61', stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <circle
          stroke='#212121'
          cx='175'
          cy='175'
          r='175'
          strokeWidth={lineWidth}
          fill='none'
        />
        <circle
          stroke='url(#grad1)'
          transform='rotate(-90 175 175)'
          cx='175'
          cy='175'
          r='175'
          strokeDasharray='1100'
          strokeWidth={lineWidth}
          strokeDashoffset='1100'
          strokeLinecap={strokeLinecap}
          fill='none'
          style={{ strokeDashoffset, transition }}
          onTransitionEnd={onAnimationEnd}
        />
        {text}
      </svg>
    );
  }, [getText, props]);

  return render();
};

ProgressCircle.defaultProps = {
  progress: 0,
  animationDuration: '1s',
  size: '100',
  lineWidth: '25',
  percentSpacing: 10,
  textStyle: { font: 'bold 4rem Helvetica, Arial, sans-serif' },
};

export default ProgressCircle;
