export const isOdd = n => n % 2 === 1;

export const displayRatioInPercentages = (ratio, skipPercentageSign) => {
  let result;
  try {
    const percentageNumber = Math.round(ratio * 10000) / 100;
    if (Number.isNaN(percentageNumber)) {
      throw Error;
    }
    result = skipPercentageSign ? percentageNumber : `${percentageNumber}%`;
  } catch (err) {
    result = 'N/A';
  } finally {
    return result;
  }
};
