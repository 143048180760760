import { useState, useEffect, useCallback, useMemo } from 'react';
import { useEventsContext } from 'contexts/events';
import { mapExperienceToClassName } from '../mappers';
import { OUTSIDE_CLICK_EVENT } from 'contexts/events/constants';
import { useUserSessionContext } from 'contexts/user-session';
import {
  ADD_PLAYER_TO_SHORTLIST_ACTION_TYPE,
  REMOVE_PLAYER_FROM_SHORTLIST_ACTION_TYPE,
} from 'contexts/user-session/hooks/constants';
import BookmarkFilledIcon from 'common/components/icons/bookmark-filled-icon';
import BookmarkIcon from 'common/components/icons/bookmark-icon';
import { DARK_MATERIAL_TERTIARY_COLOR } from 'common/constants/theming';
import { STANDARD_ANIMATION_DURATION_MS } from 'common/constants/integers';
import { addPlayerRating } from 'common/api/ratingsService';

const useProps = _data => {
  const [data, setData] = useState(_data);
  const { subscribe } = useEventsContext();
  const { updatePlayersShortlist } = useUserSessionContext();

  // skill level props
  const [showSkillLevel, setShowSkillLevel] = useState(false);

  const showSkillLevelHandler = useCallback(() => {
    setShowSkillLevel(true);
  }, []);
  const hideSkillLevelHandler = useCallback(() => {
    setShowSkillLevel(false);
  }, []);

  const experienceLevelClassName = useMemo(
    () =>
      `${mapExperienceToClassName(data?.playerGeneralInfo?.experienceLevel)}`,
    [data?.playerGeneralInfo?.experienceLevel]
  );

  // rate props
  const [showRateControls, setShowRateControls] = useState(false);
  const [showRateControlsTrailing, setShowRateControlsTrailing] =
    useState(false);
  const openRateControls = useCallback(() => {
    setShowRateControls(true);
    setShowRateControlsTrailing(true);
  }, []);

  const closeRateControls = useCallback(() => {
    setShowRateControls(false);
  }, []);

  useEffect(() => {
    if (showRateControls) return;
    const timeout = setTimeout(() => {
      setShowRateControlsTrailing(false);
      clearTimeout(timeout);
    }, STANDARD_ANIMATION_DURATION_MS);
  }, [showRateControls]);

  const rateModalClassName = useMemo(
    () =>
      showRateControls
        ? 'ratingControls__blowUpExit'
        : 'ratingControls__blowUp',
    [showRateControls]
  );

  const onRatePlayer = useCallback(
    async rating => {
      await addPlayerRating(data?._id, rating);
    },
    [data?._id]
  );

  // nationality props
  const [showNationality, setShowNationality] = useState(false);

  const showNationalityHandler = useCallback(() => {
    setShowNationality(true);
  }, []);

  const hideNationalityHandler = useCallback(() => {
    setShowNationality(false);
  }, []);

  const showNationalityClassName = useMemo(
    () =>
      showNationality ? 'playerNationality--show' : 'playerNationality--hide',
    [showNationality]
  );

  // positions props
  const [showSecondaryPositions, setShowSecondaryPositions] = useState(false);

  const showSecondaryPositionsHandler = useCallback(() => {
    setShowSecondaryPositions(true);
  }, []);

  const hideSecondaryPositionsHandler = useCallback(() => {
    setShowSecondaryPositions(false);
  }, []);

  const showSecondaryPositionsClassName = useMemo(
    () =>
      showSecondaryPositions
        ? 'secondaryPositionsWrapper--show'
        : 'secondaryPositionsWrapper--hide',
    [showSecondaryPositions]
  );

  const aBoxClassNameVariant = useMemo(
    () => `aBox--${data?.positions?.primaryArea}`,
    [data?.positions?.primaryArea]
  );

  // shortlist props

  const shortlistProps = useMemo(() => {
    const onShortlistClick = async () => {
      const actionType = data?.onShortlist
        ? REMOVE_PLAYER_FROM_SHORTLIST_ACTION_TYPE
        : ADD_PLAYER_TO_SHORTLIST_ACTION_TYPE;
      await updatePlayersShortlist(data?._id, actionType);
      setData(curr => ({ ...curr, onShortlist: !curr.onShortlist }));
    };

    const renderIcon = () => {
      if (data?.onShortlist) {
        return <BookmarkFilledIcon color={DARK_MATERIAL_TERTIARY_COLOR} />;
      } else {
        return <BookmarkIcon color={DARK_MATERIAL_TERTIARY_COLOR} />;
      }
    };

    const type = data?.onShortlist ? 'remove' : 'add';

    return {
      onClick: onShortlistClick,
      renderIcon,
      type,
    };
  }, [data, updatePlayersShortlist]);

  useEffect(() => {
    subscribe(OUTSIDE_CLICK_EVENT, () => {
      hideSkillLevelHandler();
      hideNationalityHandler();
    });
  }, [hideNationalityHandler, hideSkillLevelHandler, subscribe]);

  return {
    showSkillLevel,
    showSkillLevelHandler,
    hideSkillLevelHandler,

    showRateControls,
    showRateControlsTrailing,
    openRateControls,
    closeRateControls,
    rateModalClassName,
    onRatePlayer,

    aBoxClassNameVariant,
    experienceLevelClassName,

    showNationalityHandler,
    hideNationalityHandler,
    showNationalityClassName,

    showSecondaryPositions,
    showSecondaryPositionsHandler,
    hideSecondaryPositionsHandler,
    showSecondaryPositionsClassName,

    shortlistProps,
  };
};

export default useProps;
