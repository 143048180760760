/* eslint-disable max-len */
import { DARK_MATERIAL_TERTIARY_COLOR } from 'common/constants/theming';

const AddRateIcon = () => (
  <svg
    enable-background='new 0 0 24 24'
    height='24px'
    viewBox='0 0 24 24'
    width='24px'
    fill={DARK_MATERIAL_TERTIARY_COLOR}
  >
    <rect fill='none' height='24' width='24' />
    <path d='M8.58 10H1l6.17 4.41L4.83 22 11 17.31 17.18 22l-2.35-7.59L21 10h-7.58L11 2l-2.42 8zm12.78 12-1.86-6.01L23.68 13h-3.44l-3.08 2.2 1.46 4.72L21.36 22zM17 8l-1.82-6-1.04 3.45.77 2.55H17z' />
  </svg>
);

export default AddRateIcon;
