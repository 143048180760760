import { useCallback, useEffect, useMemo, useState } from 'react';
import AddPhotoIcon from 'common/components/icons/add-photo-icon';
import { ICON_DARK_THEME } from 'common/constants/theming';
import {
  CLUB_PROFILE_IMAGE_UPLOAD_TYPE,
  REPLACE_IMAGE_TIMEOUT,
  USER_IMAGE_LIMIT_4_MB_IN_BYTES,
  USER_IMAGE_UPLOADED_SUCCESSFULLY,
  USER_PROFILE_IMAGE_UPLOAD_TYPE,
} from '../constants';
import { USER_IMAGE_UPLOADED_EVENT } from 'contexts/events/constants';
import { useEventsContext } from 'contexts/events';
import { useMetaContext } from 'contexts/meta';
import Compressor from 'compressorjs';
import { REACT_GA_UPLOAD_PROFILE_PICTURE_EVENT } from 'common/constants/react-google-analytics/events';
import ReactGA from 'react-ga';
import { uploadServices } from './services';
import { useUserSessionContext } from 'contexts/user-session';

const useProps = ({ type }) => {
  const { emit } = useEventsContext();
  const { setSuccessToastMessage, setLocalLoading, unsetLoading } =
    useMetaContext();
  const { activeRole } = useUserSessionContext();
  const gmClub = useMemo(
    () => activeRole?.gmClub?._id,
    [activeRole?.gmClub?._id]
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(
    !!selectedFile
  );
  const [fileSource, setFileSource] = useState(null);
  const [isUploadModalOpened, setIsUploadModalOpened] = useState(false);
  const [invalidFileErrorMessage, setInvalidFileErrorMessage] = useState(null);

  const openUploadModal = useCallback(() => {
    setIsUploadModalOpened(true);
  }, []);

  const closeUploadModal = useCallback(() => {
    setIsUploadModalOpened(false);
    setSelectedFile(null);
  }, []);

  const throwErrorIfNotValidType = useCallback(
    fileType => {
      if (
        !selectedFile ||
        fileType === 'image/png' ||
        fileType === 'image/jpeg'
      )
        return;

      setInvalidFileErrorMessage(
        'Invalid file type. Please use png or jpg format.'
      );
      setSelectedFile(null);
    },
    [selectedFile]
  );

  const throwErrorIfNotValidSize = useCallback(
    fileSize => {
      if (!selectedFile) return;
      if (fileSize > USER_IMAGE_LIMIT_4_MB_IN_BYTES) {
        setInvalidFileErrorMessage(
          'File size exceeds the allowed limit of 3 MB.'
        );
        setSelectedFile(null);
      }
    },
    [selectedFile]
  );

  const onFileChange = useCallback(e => {
    setInvalidFileErrorMessage(null);
    const uploadedFile = e.target.files[0];
    new Compressor(uploadedFile, {
      quality: 0.6,
      success: compressedResult => {
        setSelectedFile(compressedResult);
        setUploadButtonDisabled(false);
        setFileSource(URL.createObjectURL(compressedResult));
      },
    });
  }, []);

  useEffect(() => {
    throwErrorIfNotValidType(selectedFile?.type);
    throwErrorIfNotValidSize(selectedFile?.size);
  }, [selectedFile, throwErrorIfNotValidSize, throwErrorIfNotValidType]);

  const fileUploadService = useCallback(async () => {
    let res;
    const formData = new FormData();
    formData.append('avatar', selectedFile, selectedFile.name);

    if (type === CLUB_PROFILE_IMAGE_UPLOAD_TYPE && gmClub) {
      res = await uploadServices?.[type](gmClub)(formData);
    } else if (type === USER_PROFILE_IMAGE_UPLOAD_TYPE) {
      res = await uploadServices?.[type]?.(formData);
    }

    return res;
  }, [gmClub, selectedFile, type]);

  const onFileUpload = useCallback(async () => {
    try {
      setLocalLoading();
      setUploadButtonDisabled(true);
      const response = await fileUploadService();
      ReactGA.event(REACT_GA_UPLOAD_PROFILE_PICTURE_EVENT);
      closeUploadModal();
      setSuccessToastMessage(USER_IMAGE_UPLOADED_SUCCESSFULLY);

      const timeout = setTimeout(() => {
        const event =
          type === USER_PROFILE_IMAGE_UPLOAD_TYPE
            ? USER_IMAGE_UPLOADED_EVENT
            : null;
        emit(event, response?.data?.image?.url);

        clearTimeout(timeout);
      }, REPLACE_IMAGE_TIMEOUT);
    } catch (error) {
      // error
    } finally {
      unsetLoading();
    }
  }, [
    closeUploadModal,
    emit,
    fileUploadService,
    setLocalLoading,
    setSuccessToastMessage,
    type,
    unsetLoading,
  ]);

  const renderAddImageIcon = useCallback(
    () => <AddPhotoIcon color={ICON_DARK_THEME} />,
    []
  );

  return {
    isUploadModalOpened,
    openUploadModal,
    closeUploadModal,

    renderAddImageIcon,

    uploadButtonDisabled,
    selectedFile,
    fileSource,
    onFileChange,
    onFileUpload,
    invalidFileErrorMessage,
  };
};

export default useProps;
