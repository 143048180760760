/* eslint-disable no-unused-vars */
import clsx from 'clsx';
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  QuickLinks,
  Search,
  ThemeModeSwitcher,
} from '../../../partials';
import { KTIcon } from '_metronic/helpers';
import { useCallback } from 'react';
import { useAuthContext } from 'contexts/auth';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass =
    'btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'fs-1';

const Topbar = ({ quickLinksProps }) => {
  const { user } = useAuthContext();
  const renderSearch = useCallback(
    () => (
      <div
        className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}
      >
        <Search />
      </div>
    ),
    []
  );

  const renderActivities = useCallback(
    () => (
      <div
        className={clsx('d-flex align-items-center ', toolbarButtonMarginClass)}
      >
        {/* begin::Drawer toggle */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          id='kt_activities_toggle'
        >
          <KTIcon
            iconName='chart-simple'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        {/* end::Drawer toggle */}
      </div>
    ),
    []
  );

  const renderNotifications = useCallback(
    () => (
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
      >
        {/* begin::Menu- wrapper */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTIcon
            iconName='element-plus'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <HeaderNotificationsMenu />
        {/* end::Menu wrapper */}
      </div>
    ),
    []
  );

  const renderChat = useCallback(
    () => (
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
      >
        {/* begin::Menu wrapper */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom position-relative',
            toolbarButtonHeightClass
          )}
          id='kt_drawer_chat_toggle'
        >
          <KTIcon
            iconName='message-text-2'
            className={toolbarButtonIconSizeClass}
          />

          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div>
        {/* end::Menu wrapper */}
      </div>
    ),
    []
  );

  const renderQuickLinks = useCallback(
    () => (
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
      >
        {/* begin::Menu wrapper */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTIcon
            iconName='element-11'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <QuickLinks {...quickLinksProps} />
        {/* end::Menu wrapper */}
      </div>
    ),
    [quickLinksProps]
  );

  const renderThemeMode = useCallback(
    () => (
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
      >
        <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass} />
      </div>
    ),
    []
  );

  const renderUser = useCallback(
    () => (
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx(
            'cursor-pointer symbol',
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img
            className='h-30px w-30px rounded'
            src={user?.image?.url}
            alt='metronic'
          />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
    ),
    [user?.image?.url]
  );

  const render = useCallback(() => {
    const renderCallbacks = [
      // renderSearch,
      // renderActivities,
      // renderNotifications,
      // renderChat,
      renderQuickLinks,
      renderThemeMode,
      renderUser,
    ];

    return renderCallbacks.map(callback => callback());
  }, [
    // renderActivities,
    // renderChat,
    // renderNotifications,
    renderQuickLinks,
    // renderSearch,
    renderThemeMode,
    renderUser,
  ]);

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {render()}
      </div>
    </div>
  );
};

export { Topbar };
