import PropTypes from 'prop-types';

const PlayerCardV2PropTypes = {
  // TODO-BACKLOG user data coming from BE
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    positions: PropTypes.shape({
      natural: PropTypes.string,
      primaryArea: PropTypes.string,
      secondaryAreas: PropTypes.arrayOf(PropTypes.string),
    }),
    playerGeneralInfo: PropTypes.shape({
      experienceLevel: PropTypes.string,
    }),
    geoInfo: PropTypes.shape({
      city: PropTypes.string,
    }),
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })
    ),
    // ---
    onShortlist: PropTypes.bool,
  }),
};

export default PlayerCardV2PropTypes;
