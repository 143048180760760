// import SVG from 'react-inlinesvg';
// type Props = {
//   className?: string
//   path: string
//   svgClassName?: string
// }

export const KTSVG = ({ className = '', path, svgClassName = 'mh-50px' }) => {
  return (
    <span className={`svg-icon ${className}`}>
      {/* <SVG src={toAbsoluteUrl(path)} className={svgClassName} /> */}
    </span>
  );
};
