import React, { useCallback, useEffect } from 'react';
import { useCommonClassNames, useNavigation } from 'common/hooks';
import { PrivatePageWrapper } from 'features/page-wrapper';
import { useProps } from './hooks';
import style from './Street.module.scss';
import StreetV2 from './components/street-v2';
import {
  CLUB_STREET_ITEM_TYPE,
  PLAYER_STREET_ITEM_TYPE,
  playersScreenStaticProps,
  STREET_SCREENS,
} from './constants';
import Catalogue from 'pages/street/components/catalogue';
import { scrollToTop } from 'common/utils/dom';

import { CreateEditGameEvent, GameEvent } from 'features/game-event-features';
import PlayersView from './components/players-view';
import Banner from 'common/components/banner';
import { GLOBAL_LOADING_TYPE } from 'common/components/loading';

const Street = () => {
  const {
    cardItems,
    setSelectedMatch,
    tabsData,

    loadLocalStreetData,

    players,

    // street navigation
    selectedScreen,
    selectEntranceScreen,
    streetNavigationPropsSet,

    // game event
    selectedGameEvent,
    closeGameEvent,

    // loading
    loading,
  } = useProps();
  const { animationClassName } = useCommonClassNames(false);
  const { navigateToClubPage, navigateToUserPage } = useNavigation();

  const goTo = useCallback(
    (type, id) => {
      if (type === CLUB_STREET_ITEM_TYPE) {
        navigateToClubPage(id);
      } else if (type === PLAYER_STREET_ITEM_TYPE) {
        navigateToUserPage(id);
      }
    },
    [navigateToClubPage, navigateToUserPage]
  );

  const renderView = useCallback(() => {
    switch (selectedScreen) {
      case STREET_SCREENS.gamesStreetScreen:
        return (
          <StreetV2
            {...cardItems}
            onMatchCardClick={setSelectedMatch}
            goToHandler={goTo}
            tabs={tabsData}
            goBackHandler={selectEntranceScreen}
          />
        );
      // case TEAMS_STREET_SCREEN:
      //   return (
      //     <StreetV2
      //       {...cardItems}
      //       onMatchCardClick={setSelectedMatch}
      //       goToHandler={goTo}
      //       tabs={tabsData}
      //       goBackHandler={selectEntranceScreen}
      //       tips={teamsScreenTips}
      //     />
      //   );
      case STREET_SCREENS.playersStreetScreen:
        return (
          <PlayersView
            goBack
            goBackHandler={selectEntranceScreen}
            items={players}
          />
        );
      case STREET_SCREENS.createGameStreetScreen:
      default:
        return (
          <Catalogue
            screenCards={streetNavigationPropsSet}
            loadData={loadLocalStreetData}
          />
        );
    }
  }, [
    cardItems,
    goTo,
    loadLocalStreetData,
    players,
    selectEntranceScreen,
    selectedScreen,
    setSelectedMatch,
    streetNavigationPropsSet,
    tabsData,
  ]);

  useEffect(() => {
    scrollToTop();
  }, [selectedScreen]);

  if (loading === GLOBAL_LOADING_TYPE) {
    return null;
  }

  return (
    <PrivatePageWrapper fullscreen>
      <Banner img={playersScreenStaticProps.banner} />
      <div
        className={`${style.wrapper} ${style[`wrapper${animationClassName}`]}`}
      >
        {renderView()}

        {/* -- MODALS -- */}

        {selectedGameEvent && (
          <GameEvent gameData={selectedGameEvent} onClose={closeGameEvent} />
        )}

        {/* create game event */}
        {selectedScreen === STREET_SCREENS.createGameStreetScreen && (
          <CreateEditGameEvent onClose={selectEntranceScreen} />
        )}
      </div>
    </PrivatePageWrapper>
  );
};

export default Street;
