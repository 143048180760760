/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useContext, useEffect, useState } from 'react';

// export interface PageLink {
//   title: string
//   path: string
//   isActive: boolean
//   isSeparator?: boolean
// }

// export interface PageDataContextModel {
//   pageTitle?: string
//   setPageTitle: (_title: string) => void
//   pageDescription?: string
//   setPageDescription: (_description: string) => void
//   pageBreadcrumbs?: Array<PageLink>
//   setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
// }

const PageDataContext = {
  setPageTitle: _title => {},
  setPageBreadcrumbs: _breadcrumbs => {},
  setPageDescription: _description => {},
};

const PageDataProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('');
  const [pageDescription, setPageDescription] = useState('');
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState([]);
  const value = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
  };
  return (
    <PageDataContext.Provider value={value}>
      {children}
    </PageDataContext.Provider>
  );
};

function usePageData() {
  return useContext(PageDataContext);
}

// type Props = {
//   description?: string
//   breadcrumbs?: Array<PageLink>
// }

const PageTitle = ({ children, description, breadcrumbs }) => {
  const { setPageTitle, setPageDescription, setPageBreadcrumbs } =
    usePageData();
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString());
    }
    return () => {
      setPageTitle('');
    };
  }, [children]);

  useEffect(() => {
    if (description) {
      setPageDescription(description);
    }
    return () => {
      setPageDescription('');
    };
  }, [description]);

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs);
    }
    return () => {
      setPageBreadcrumbs([]);
    };
  }, [breadcrumbs]);

  return <></>;
};

const PageDescription = ({ children }) => {
  const { setPageDescription } = usePageData();
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString());
    }
    return () => {
      setPageDescription('');
    };
  }, [children]);
  return <></>;
};

export { PageDescription, PageTitle, PageDataProvider, usePageData };
